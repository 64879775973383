import { Link } from "gatsby";
import React, { Fragment } from "react";
import { ReactSVG as SVG } from "react-svg";
import styled from "styled-components";

import { SiteOptions } from "../custom-gatsby";
import Container from "./Container";

type FooterProps = {
  menu?: Queries.WpMenu["menuItems"];
  options: SiteOptions;
};

const Footer = (props: FooterProps) => {
  let { menu, options } = props;

  let {
    address,
    telephone,
    registrationNumber,
    sponsorsTitle,
    sponsors,
    socialMedia,
    affiliationsTitle,
    affiliations,
  } = options ?? {};

  return (
    <Outer className={"footer"}>
      <Upper>
        <Container size={"wide"}>
          <FooterLeft>
            {options?.logo?.node ? (
              <Link to={"/"}>
                <img
                  src={options.logo.node.sourceUrl ?? ""}
                  alt={options.logo.node.altText ?? ""}
                />
              </Link>
            ) : null}

            {socialMedia?.length ? (
              <SocialLinks>
                {socialMedia.map((item) => {
                  const { type, url } = item ?? {};
                  return (
                    <li>
                      <a href={url ?? ""} target={"_blank"}>
                        <SVG src={`/icons/${type}.svg`} />
                      </a>
                    </li>
                  );
                })}
              </SocialLinks>
            ) : null}

            {telephone ? <a href={`tel:${telephone}`}>{telephone}</a> : null}

            {registrationNumber ? <span>{registrationNumber}</span> : null}
          </FooterLeft>

          <FooterRight>
            {address ? (
              <address dangerouslySetInnerHTML={{ __html: address }} />
            ) : null}

            {menu?.nodes?.length ? (
              <Menu>
                <ul>
                  {menu?.nodes.map((item: Queries.WpMenuItem) => {
                    const { url: _url, path, label, cssClasses } = item;

                    const url =
                      _url?.includes("#") || _url?.charAt(0) === "/"
                        ? _url
                        : path;

                    return (
                      <li key={`footerMenu-${label}`}>
                        <Link to={url ?? ""} className={cssClasses?.join(" ")}>
                          {label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </Menu>
            ) : null}

            {!!sponsors?.length ? (
              <Sponsors>
                {sponsorsTitle ? <h6>{sponsorsTitle}</h6> : null}

                <Logos>
                  {sponsors.map((item) => {
                    const { image, name, url } = item ?? {};
                    if (!image) return null;

                    return (
                      <Fragment key={`footer-logo-${name}`}>
                        {url ? (
                          <a href={url} target={"_blank"}>
                            <img
                              src={image.node.sourceUrl ?? ""}
                              alt={image.node.altText ?? ""}
                            />
                          </a>
                        ) : (
                          <img
                            src={image.node.sourceUrl ?? ""}
                            alt={image.node.altText ?? ""}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </Logos>
              </Sponsors>
            ) : null}

            {!!affiliations?.sponsors?.length ? (
              <Affiliates>
                {affiliationsTitle ? <h6>{affiliationsTitle}</h6> : null}

                <Logos>
                  {affiliations?.sponsors.map((item) => {
                    const { image, name, url } = item ?? {};
                    if (!image) return null;

                    return (
                      <div
                        key={`footer-logo-${name}`}
                        className={"footer-logo"}
                      >
                        {url ? (
                          <a href={url} target={"_blank"}>
                            <img
                              src={image.node.sourceUrl ?? ""}
                              alt={image.node.altText ?? ""}
                            />
                          </a>
                        ) : (
                          <img
                            src={image.node.sourceUrl ?? ""}
                            alt={image.node.altText ?? ""}
                          />
                        )}
                      </div>
                    );
                  })}
                </Logos>
              </Affiliates>
            ) : null}
          </FooterRight>
        </Container>
      </Upper>

      <Lower>
        <Container size={"wide"}>
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
          <span>&copy; WR Investigations {new Date().getFullYear()}</span>
        </Container>
      </Lower>
    </Outer>
  );
};

const SocialLinks = styled.ul`
  display: flex;
  padding: 0;
  margin: 5px 0 20px !important;
  list-style-type: none;

  li {
    + li {
      margin-left: 12px;
    }
  }

  svg {
    width: 16px;
    fill: ${({ theme }) => theme.color.blue};
    transition: 0.3s;
  }

  a {
    &:hover {
      svg {
        fill: ${({ theme }) => theme.color.blueHover};
      }
    }
  }
`;

const Menu = styled.nav``;

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .footer-logo {
    + * {
      margin-left: 16px;
    }
  }

  img {
    width: auto;
    max-width: 252px;
    max-height: 140px;
  }

  @media (max-width: 991px) {
    align-items: center;
  }
`;

const Affiliates = styled.div``;

const Sponsors = styled.div`
  img {
    height: 60px;
  }
`;

const FooterLeft = styled.div`
  img {
    width: 82px;
    margin-bottom: 18px;
  }

  span {
    display: block;
  }

  > * {
    + * {
      margin-top: 15px;
    }
  }
`;

const FooterRight = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  padding-left: 12%;

  nav,
  address {
    line-height: 192%;
    font-family: ${({ theme }) => theme.fontFamily.sansMedium};
  }

  > * {
    + * {
      margin-left: 50px;
    }
  }
`;

const Upper = styled.div`
  padding: 35px 0;
  background: ${({ theme }) => theme.color.white};

  ${Container} {
    display: flex;
    justify-content: space-between;
  }

  a,
  h6 {
    color: ${({ theme }) => theme.color.text};
  }
`;

const Lower = styled.div`
  padding: 20px 0;
  background: ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.fontFamily.sans};
  font-size: ${({ theme }) => theme.fontSize.bodySm};

  ${Container} {
    display: flex;
    justify-content: flex-end;

    > * {
      + * {
        padding-left: 11px;
        margin-left: 11px;
        border-left: 1px solid white;
      }
    }
  }

  a {
    color: ${({ theme }) => theme.color.white};
  }
`;

const Outer = styled.footer`
  font-family: ${({ theme }) => theme.fontFamily.sansMedium};

  a,
  h6 {
    display: table;
    text-decoration: none;
    font-family: ${({ theme }) => theme.fontFamily.sansMedium};
    white-space: nowrap;
  }

  ${Lower} {
    a {
      font-family: ${({ theme }) => theme.fontFamily.sans};
    }
  }

  h6 {
    margin-bottom: 12px;
    white-space: nowrap;
  }

  @media (max-width: 1289px) {
    ${FooterRight} {
      padding-left: 8%;
    }

    ${Logos} {
      flex-direction: column;

      .footer-logo {
        + * {
          margin: 12px 0 0;
        }
      }
    }
  }

  @media (max-width: 991px) {
    ${Upper} {
      ${Container} {
        flex-direction: column;
      }
    }

    ${FooterRight} {
      order: -1;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0;
      text-align: center;

      > * {
        width: 100%;

        + * {
          margin: 35px 0 0 0;
        }
      }
    }

    ${Sponsors},
    ${Affiliates} {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    ${Logos} {
      flex-direction: row;
      justify-content: center;

      .footer-logo {
        + * {
          margin: 0 0 0 32px;
        }
      }
    }

    ${Menu} {
      a {
        margin-left: auto;
        margin-right: auto;
      }
    }

    ${FooterLeft} {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
    }

    h6 {
      text-align: center;
    }
  }

  @media (max-width: 479px) {
    ${Logos} {
      flex-direction: column;
      align-items: center;

      .footer-logo {
        + * {
          margin: 12px auto 0;
        }
      }
    }
  }
`;

export default Footer;
