import { graphql, Link, useStaticQuery } from "gatsby";
import React, { Fragment, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import ReactModal from "react-modal";
import { ThemeProvider } from "styled-components";
import { Reset } from "styled-reset";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { SiteOptions } from "../custom-gatsby";
import GlobalStyles from "../styles/global";
import theme from "../styles/theme";

ReactModal.setAppElement("#___gatsby");

type LayoutProps = {
  children: React.ReactNode;
};

const MainLayout = ({ children }: LayoutProps) => {
  const _options = useStaticQuery<Queries.SiteOptionsQuery>(graphql`
    query MainLayout {
      allWp(limit: 1) {
        nodes {
          siteOptionsPage {
            siteOptions {
              logo {
                node {
                  sourceUrl
                  altText
                }
              }
              text404
              title404
              seoTitle
              seoDescription
              seoSeparator
              seoImages {
                image {
                  node {
                    sourceUrl
                    altText
                  }
                }
              }
              placeholderImage {
                node {
                  sourceUrl
                  altText
                }
              }
              address
              telephone
              registrationNumber
              sponsorsTitle
              sponsors {
                name
                url
                image {
                  node {
                    sourceUrl
                    altText
                  }
                }
              }
              socialMedia {
                type
                url
              }
              affiliationsTitle
              affiliations {
                sponsors {
                  name
                  url
                  image {
                    node {
                      sourceUrl
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }
      mainMenu: wpMenu(name: { eq: "Main Menu" }) {
        menuItems {
          nodes {
            url
            label
            label
            target
            cssClasses
            path
            childItems {
              nodes {
                label
                path
              }
            }
          }
        }
      }
      footerMenu: wpMenu(name: { eq: "Footer Menu" }) {
        menuItems {
          nodes {
            url
            label
            label
            target
            cssClasses
            path
          }
        }
      }
    }
  `);
  const options = _options?.allWp.nodes[0].siteOptionsPage
    ?.siteOptions as SiteOptions;
  const mainMenu = _options?.mainMenu?.menuItems;
  const footerMenu = _options?.footerMenu?.menuItems;

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Reset />
        <GlobalStyles />

        <Header menu={mainMenu} options={options} />
        {children}
        <Footer menu={footerMenu} options={options} />
        <CookieConsent
          location="bottom"
          cookieName="wri-website-cookie-consent"
          style={{
            background: theme.color.blue,
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
          buttonStyle={{
            backgroundColor: theme.colors.main,
            padding: "9px 23px",
            borderRadius: "4px",
            border: 0,
            fontSize: "16px",
            textDecoration: "none",
            color: theme.color.text,
          }}
        >
          This website uses cookies to enhance the user experience and analyze
          performance and traffic on our website. Please see our{" "}
          <Link to="/privacy-policy">Privacy Policy</Link> for more information.
        </CookieConsent>

        <HiddenForms />
      </Fragment>
    </ThemeProvider>
  );
};

const HiddenForms = () => (
  <Fragment>
    <form
      name={"contact"}
      data-netlify="true"
      netlify-honeypot="honeypot"
      style={{
        display: "none",
      }}
    >
      <input type="email" name={"email"} />
      <input type="checkbox" name={"message"} />
    </form>
  </Fragment>
);

export default MainLayout;
