import styled from "styled-components";

interface ContainerProps {
  size?: "wide" | "narrow" | "default";
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${({ size }) => {
    if (size === "wide") return "1340px";
    if (size === "narrow") return "940px";

    return "1180px";
  }};
  padding: 0 20px;
  margin: 0 auto;
`;

export default Container;
