import { Link } from "gatsby";
import React, { Fragment, useEffect, useState } from "react";
import { ReactSVG as SVG } from "react-svg";
import styled from "styled-components";

import { SiteOptions } from "../custom-gatsby";
import Button from "./Button";
import Container from "./Container";
// import { BrandButton } from '../Buttons';
// import { Context } from '../Misc';

type HeaderProps = {
  menu?: Queries.WpMenu["menuItems"];
  options: SiteOptions;
};

const Header = (props: HeaderProps) => {
  let { menu, options } = props;

  let [menuOpen, setMenuOpen] = useState<boolean>(false);

  let location;
  if (typeof window !== "undefined") {
    location = window.location.href;
  }

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <Fragment>
      <HeaderOuter>
        <Inner>
          <Container size={"wide"}>
            {options?.logo?.node ? (
              <Link to={"/"}>
                <img
                  src={options?.logo?.node.sourceUrl ?? ""}
                  alt={options?.logo?.node.altText ?? ""}
                />
              </Link>
            ) : null}

            {!!menu?.nodes?.length ? (
              <Fragment>
                <Menu>{renderMenu(menu)}</Menu>

                <Hamburger onClick={() => setMenuOpen(!menuOpen)}>
                  <SVG src={"/icons/bars.svg"} />
                </Hamburger>
              </Fragment>
            ) : null}
          </Container>
        </Inner>
        <MobileMenu open={menuOpen}>{renderMenu(menu)}</MobileMenu>
      </HeaderOuter>
    </Fragment>
  );
};

const renderMenu = (menu?: Queries.WpMenu["menuItems"]) => (
  <ul>
    {menu?.nodes.map((item: Queries.WpMenuItem, i) => {
      const { url: _url, path, label, cssClasses, childItems } = item;

      const url = _url?.includes("#") ? _url : path;

      return (
        <li
          className={url === "#" ? "has-children" : ""}
          key={`headerMenu-${label}`}
        >
          {i + 1 === menu.nodes.length ? (
            <Button
              as={Link}
              to={url ?? ""}
              className={cssClasses?.map((c) => c).join(" ")}
              size={"small"}
            >
              {label}
            </Button>
          ) : (
            <Link
              to={url ?? ""}
              className={cssClasses?.map((c) => c).join(" ")}
            >
              {label}
            </Link>
          )}

          {childItems?.nodes?.length ? (
            <ul className={"sub-menu"}>
              {childItems.nodes.map((child: Queries.WpMenuItem) => (
                <li key={`headerSubMenu-${child.label}`}>
                  <Link
                    to={
                      child.url === "#"
                        ? child.url
                        : `${
                            path === "poet-in-action" ? "case-study" : path
                          }/${child.path}`
                    }
                    className={child.cssClasses?.join(" ")}
                  >
                    {child.label}
                  </Link>
                </li>
              ))}
            </ul>
          ) : null}
        </li>
      );
    })}
  </ul>
);

const Inner = styled.div`
  position: relative;
  z-index: 2;
  background: white;
`;

const Hamburger = styled.button`
  border: 0;
  background: none;

  svg {
    width: 22px;
    fill: ${({ theme }) => theme.color.brand};
  }

  &:focus {
    outline: 0;
  }

  @media (min-width: 700px) {
    display: none;
  }
`;

const MobileMenu = styled.nav<{ open: boolean }>`
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  transform: translateY(${({ open }) => (open ? "0" : "calc(-100% - 100px)")});
  transition: transform 0.5s ease-in-out;
  background: ${({ theme }) => theme.color.blue};
  z-index: 1;

  a {
    display: block;
    padding: 25px 15px;
    text-align: center;
    text-decoration: none;
    color: ${({ theme }) => theme.color.white};
    font-family: ${({ theme }) => theme.fontFamily.sansMedium};
    font-size: ${({ theme }) => theme.fontSize.bodyLg};

    &:active:focus {
      outline: 0;
    }
  }

  @media (min-width: 700px) {
    display: none;
  }
`;

const Menu = styled.nav`
  ul {
    display: flex;
    align-items: center;

    li {
      + li {
        margin-left: 48px;
      }

      &:not(:last-of-type) {
        a {
          text-decoration: none;
          color: ${({ theme }) => theme.color.text};
          font-family: ${({ theme }) => theme.fontFamily.sansMedium};

          &:active:focus {
            outline: 0;
          }
        }
      }
    }
  }

  @media (max-width: 699px) {
    display: none;
  }
`;

const HeaderOuter = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.color.white};
  z-index: 22;

  ${Container} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;

    img {
      position: relative;
      top: 4px;
      width: 110px;
    }
  }
`;

export default Header;
