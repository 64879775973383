import { createGlobalStyle } from "styled-components";

import fontFiles from "./fonts";
import theme from "./theme";

const GlobalStyles = createGlobalStyle`
	@font-face {
		font-family: 'Avenir';
		font-style: normal;
		font-weight: 400;
		src: local("Avenir"), local("Avenir"), url(${fontFiles.AvenirNextOTF}) format("otf"), url(${fontFiles.AvenirNextWOFF}) format("woff");
	}
	
	@font-face {
		font-family: 'Avenir Heavy';
		font-style: normal;
		font-weight: 700;
		src: local("Avenir Heavy"), local("Avenir Heavy"), url(${fontFiles.AvenirHeavyTTF}) format("ttf"), url(${fontFiles.AvenirHeavyWOFF}) format("woff");
	}
	
	@font-face {
		font-family: 'Avenir Light';
		font-style: normal;
		font-weight: 300;
		src: local("Avenir Light"), local("Avenir Light"), url(${fontFiles.AvenirLightTTF}) format("ttf"), url(${fontFiles.AvenirLightWOFF}) format("woff");
	}
	
	@font-face {
		font-family: 'Avenir Medium';
		font-style: normal;
		font-weight: 500;
		src: local("Avenir Medium"), local("Avenir Medium"), url(${fontFiles.AvenirNextSemiBoldOTF}) format("otf"), url(${fontFiles.AvenirNextSemiBoldWOFF}) format("woff");
	}
	
	html {
		box-sizing: border-box;
		overflow-x: hidden;
	}
	*, *:before, *:after {
		box-sizing: inherit;
	}
	
	input:required {
		box-shadow: none;
	}

	body {
		position: relative;
		min-height: 100vh;
		margin: 0;
		// padding: 100px 0 298px;
		padding: 100px 0 0;
		font-family: ${theme.fontFamily.sans};
		font-size: 16px;
		overflow-x: hidden;
		color: ${theme.color.text};
		
		.footer {
			// position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}
		
		&:not(.user-is-tabbing) button:focus,
		&:not(.user-is-tabbing) input:focus,
		&:not(.user-is-tabbing) select:focus,
		&:not(.user-is-tabbing) textarea:focus,
		&:not(.user-is-tabbing) a:focus,
		&:not(.user-is-tabbing) li:focus {
			outline: 0;
		}
		
		// @media (max-width: 1289px) {
		// 	padding-bottom: 357px;
		// }
		// @media (max-width: 991px) {
		// 	padding-bottom: 946px;
		// }
		// @media (max-width: 479px) {
		// 	padding-bottom: 1005px;
		// }
	}
	
	a {
		transition: ${theme.duration.normal}ms;
	}
	
	h1,h2,h3,h4,h5,h6 {
		margin: 0;
		line-height: 140%;
	}
	
	strong,b {
		font-weight: 400;
		font-family: ${theme.fontFamily.sansHeavy};
	}
	
	p {
		margin: 0;
		line-height: 180%;
		
		+* {
			margin-top: 20px;
		}
	}

	svg {
		display: block;
	}
	
	img {
		display: block;
		max-width: 100%;
	}
	
	button {
		cursor: pointer;
		font-family: ${theme.fontFamily.sans};
	}
	
	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 38px;
		padding: 5px 18px;
		color: ${theme.color.white};
		background: ${theme.color.brand};
		border-radius: ${theme.radius.round};
	}
	
	.btn--brand-alt {
		background: ${theme.color.blue};
	}
	
	.btn--upper {
		text-transform: uppercase;
	}
	
	.sr-only {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0,0,0,0);
		border: 0;
	}
	
	.user-content {
		h1,h2,h3,h4,h5,h6 {
			font-family: ${theme.fontFamily.sansHeavy};
			line-height: 125%;
			
			+* {
				margin-top: 20px;
			}
		}
		
		h1,h2 {
			font-size: ${({ theme }) => theme.fontSize.titleMd};
		}
		
		h3,h4,h5,h6 {
			font-size: ${({ theme }) => theme.fontSize.titleSm};
		}
		
		ul {
			list-style: initial;
			padding-left: 18px;
			
			li {
				+li {
					margin-top: 8px;
				}
			}
		}
		
		* {
			line-height: 180%;
			
			+h1,+h2,+h3,+h4,+h5,+h6 {
				margin-top: 35px;
			}
		}
		
		em {
			font-style: italic;
		}
		
		a {
			color: ${({ theme }) => theme.color.blueLight};
			text-decoration: none;
		}
	}
	
	.headroom-wrapper {
		.headroom {
			z-index: 20!important;
		}
		
		&:first-of-type {
			.headroom {
				z-index: 21!important;
			}
		}
	}
	
	.modal-video {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	
	input,select {
		height: 52px;
	}
	
	input,textarea,select {
		padding: 15px 18px;
		border: 0;
		background: ${({ theme }) => theme.color.whiteish};
		font-family: ${({ theme }) => theme.fontFamily.sans};
		font-size: ${({ theme }) => theme.fontSize.body};
		color: ${({ theme }) => theme.color.text};
		
		&:invalid {
			box-shadow: none;
		}
		
		&:placeholder {
			font-size: ${({ theme }) => theme.fontSize.body};
			color: ${({ theme }) => theme.color.text};
		}
		
		&::placeholder {
			font-size: ${({ theme }) => theme.fontSize.body};
			color: ${({ theme }) => theme.color.text};
		}
	}
`;

export default GlobalStyles;
