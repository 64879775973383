import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  color: {
    brand: "#E84040",
    brandHover: "#E02020",
    // brandHover: '#D13434',
    danger: "#E84040",
    disabled: "#cdcdca",
    success: "#6CD86C",
    successHover: "#6CD8AA",
    text: "#343F54",
    white: "#fff",
    whiteish: "#F6F6F6",
    blue: "#343F54",
    blueAlt: "#586173",
    grey: "#F6F6F6",
    link: "#6D9ECE",

    brandDark: "#FFBF00",
    blueLight: "#69B6E2",
    blueHover: "#384558",
    greyDark: "#D9DDE1",
    black: "#001C32",
  },
  colors: {
    main: "#69B6E2",
    secondary: "#FFBF00",
  },
  fontSize: {
    titleXxl: "54px",
    titleXl: "46px",
    titleLg: "42px",
    titleLgMd: "36px",
    titleMd: "32px",
    title: "28px",
    titleSm: "20px",
    bodyLg: "20px",
    bodyMd: "17px",
    body: "16px",
    bodySm: "14px",
  },
  fontFamily: {
    sans: '"Avenir", sans-serif',
    sansMedium: '"Avenir Medium", sans-serif',
    sansHeavy: '"Avenir Heavy", sans-serif',
    sansLight: '"Avenir Light", sans-serif',
  },
  radius: {
    tweetCard: "4px 0 4px 4px",
    round: "40px",
    card: "4px",
  },
  shadow: {
    card: "0 5px 10px 0 rgba(0,0,0,0.10)",
    tweetCard: "2px 2px 10px 0 rgba(52,63,84,0.20)",
  },
  duration: {
    normal: 300,
    quick: 150,
  },
  opacity: {
    colorOverlay: "0.6",
  },
  border: {
    input: "1px solid #435269",
  },
  borderRadius: "4px",
};

export default theme;
